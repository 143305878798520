@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.banner {
    background-position: 0 35%;
    background-repeat: no-repeat;
    background-size: cover;

    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, .7)), url('./_images/testimonial-banner.jpg');

    @include media-breakpoint-up(md) {
        background-position: 0 40%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, .3) 70%, rgba(255, 255, 255, 0)), url('./_images/testimonial-banner.jpg');
    }

    @include media-breakpoint-up(lg) {
        background-position: 0 35%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .3) 60%, rgba(255, 255, 255, 0)), url('./_images/testimonial-banner.jpg');
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.banner {
    background-position: 0 30%;
    background-repeat: no-repeat;
    background-size: cover;

    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, .7)), url('./_images/faq-banner.jpg');

    @include media-breakpoint-up(md) {
        background-position: 0 20%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, .7) 60%, rgba(255, 255, 255, .4) 70%, rgba(255, 255, 255, 0)), url('./_images/faq-banner.jpg');
    }

    @include media-breakpoint-up(lg) {
        background-position: 0 30%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .4) 60%, rgba(255, 255, 255, 0)), url('./_images/faq-banner.jpg');
    }
}

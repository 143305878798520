@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.bannerVideo {
    opacity: 0.25;
    top: -18%;

    @include media-breakpoint-up(sm) {
        top: -40%;
    }

    @include media-breakpoint-up(md) {
        top: -70%;
    }

    @include media-breakpoint-up(lg) {
        top: -95%;
    }
}

.bannerImage {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner1 {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.90), rgba(255, 255, 255, .90)), url('./_images/pattern.jpg');
}

.banner2 {
    background-position: 60%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, .7)), url('./_images/books.jpg');

    @include media-breakpoint-up(md) {
        background-position: center;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, .6) 60%, rgba(255, 255, 255, .2) 70%, rgba(255, 255, 255, 0)), url('./_images/books.jpg');
    }

    @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .2) 60%, rgba(255, 255, 255, 0)), url('./_images/books.jpg');
    }
}

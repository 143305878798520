$brandBlue:     #284D68;
$brandGreen:    #50A699;
$brandYellow:   #CCCF5B;
$brandPurple:   #342659;
$brandPink:     #CE6A6C;

$theme-colors: (
    "blue":     $brandBlue,
    "green":    $brandGreen,
    "yellow":   $brandYellow,
    "purple":   $brandPurple,
    "pink":     $brandPink
);

$enable-responsive-font-sizes: true;

$navbar-dark-color: white;

@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body { font-family: 'Montserrat' }
